import { dateDiff, sqlDate, sqlDatetime } from "../plugins/variables"

export class ArrosageCuvier {
  private sa_num: string
  private sa_id: string
  private sc_code: string
  private sa_debut: string
  private sa_fin: string
  private sa_duree: number
  private sa_froid: string

  constructor()
  constructor(unArrosage: ArrosageCuvier)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sa_num = args[0].sa_num
      this.sa_id = args[0].sa_id
      this.sc_code = args[0].sc_code
      this.sa_debut = args[0].sa_debut
      this.sa_fin = args[0].sa_fin
      this.sa_duree = args[0].sa_duree
      this.sa_froid = args[0].sa_froid
    } else {
      this.sa_num = ""
      this.sa_id = ""
      this.sc_code = ""
      this.sa_debut = ""
      this.sa_fin = ""
      this.sa_duree = -1
      this.sa_froid = "n"
    }
  }

  getNumerosArrosage() {
    return this.sa_num
  }
  getId() {
    return this.sa_id
  }
  getCodeCuvier() {
    return this.sc_code
  }
  getDebutArrosage() {
    return this.sa_debut
  }
  getFinArrosage() {
    return this.sa_fin
  }
  getDuree() {
    return this.sa_duree
  }
  getFroid() {
    return this.sa_froid
  }
  iniNouveauCycle(sc_code: string) {
    const id = new Date().getTime() + ""
    this.sa_id = id
    this.sa_num = id
    this.sc_code = sc_code
    this.sa_debut = this.sa_debut = sqlDatetime(new Date())
    this.sa_duree = 0
  }
  iniNouveauArrosage(unCuvier: Cuvier) {
    this.sa_num = unCuvier.getArrosages()[0].getNumerosArrosage()
    this.sa_id = new Date().getTime() + ""
    this.sc_code = unCuvier.getCode()
    this.sa_debut = sqlDatetime(new Date())
    this.sa_duree = 0
  }
  setFinArrosage() {
    this.sa_fin = sqlDatetime(new Date())
    this.sa_duree = dateDiff(new Date(this.sa_debut), new Date(this.sa_fin))
  }
  setDuree(sa_duree: number) {
    this.sa_duree = sa_duree
  }
  setFroid() {
    this.sa_froid = "o"
  }
}

export class Cuvier {
  private sc_code: string
  private sc_situation: string
  private lesArrosages: ArrosageCuvier[]
  private sl_code: string
  private num_charge: string
  private sc_dateDerniereSituation: string
  private sc_nbVoyage: number
  private sc_dateEntrer: Date
  private sc_dateSortie: Date | null
  private sc_observation: string
  private sg_code: string
  private AEnvoyerALaBD: boolean

  constructor()
  constructor(c: Cuvier)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sc_code = args[0].sc_code
      this.sc_situation = args[0].sc_situation
      if (args[0].lesArrosages === undefined || args[0].lesArrosages.length == 0) {
        this.lesArrosages = []
      } else {
        this.lesArrosages = args[0].lesArrosages.map((a: ArrosageCuvier) => {
          return new ArrosageCuvier(a)
        })
      }
      this.sl_code = args[0].sl_code
      this.num_charge = args[0].num_charge
      this.sc_dateDerniereSituation = args[0].sc_dateDerniereSituation
      this.sc_nbVoyage = args[0].sc_nbVoyage
      this.sc_dateEntrer = args[0].sc_dateEntrer
      this.sc_dateSortie = args[0].sc_dateSortie
      this.sc_observation = args[0].sc_observation
      this.sg_code = args[0].sg_code
      this.AEnvoyerALaBD = args[0].AEnvoyerALaBD
    } else {
      this.sc_code = ""
      this.sc_situation = ""
      this.lesArrosages = []
      this.sl_code = ""
      this.num_charge = ""
      this.sc_dateDerniereSituation = ""
      this.sc_nbVoyage = 0
      this.sc_dateEntrer = new Date()
      this.sc_dateSortie = new Date()
      this.sc_observation = ""
      this.sg_code = ""
      this.AEnvoyerALaBD = false
    }
  }
  getCode() {
    return this.sc_code
  }
  getSituation() {
    return this.sc_situation
  }
  getArrosages() {
    return this.lesArrosages
  }
  getSl_Code() {
    return this.sl_code
  }
  getNumCharge() {
    return this.num_charge
  }
  getDateDerSituation() {
    return this.sc_dateDerniereSituation
  }
  getNbVoyage() {
    return this.sc_nbVoyage
  }
  getDateEntrer() {
    return this.sc_dateEntrer
  }
  getDateSortie() {
    return this.sc_dateSortie
  }
  getObservation() {
    return this.sc_observation
  }
  getGroupe() {
    return this.sg_code
  }
  getAEnvoyerALaBD() {
    return this.AEnvoyerALaBD
  }
  getDernierArrosage() {
    if (this.lesArrosages.length > 0) {
      return this.lesArrosages[this.lesArrosages.length - 1]
    } else {
      return new ArrosageCuvier()
    }
  }
  setNbVoyage(nbVoyage: number) {
    this.sc_nbVoyage = nbVoyage
  }
  setAEnvoyerALaBD(AEnvoyerALaBD: boolean) {
    this.AEnvoyerALaBD = AEnvoyerALaBD
  }
  setSituation(sc_situation: string, sc_dateDerniereSituation: string) {
    this.sc_situation = sc_situation
    this.sc_dateDerniereSituation = sc_dateDerniereSituation
  }
  setObservation(sc_observation: string) {
    this.sc_observation = sc_observation
  }
  setSl_Code(sl_code: string) {
    this.sl_code = sl_code
  }
  setNumCharge(num_charge: string) {
    this.num_charge = num_charge
  }
  estDuGroupe(sg_code: string) {
    if (this.sg_code == sg_code) {
      return true
    } else {
      return false
    }
  }
  aFaitUnVoyage(new_situation: string) {
    if (this.getGroupe() == "C") {
      if (this.getSituation().substring(0, 1) == "C" && new_situation.substring(0, 6) == "Camion") {
        this.sc_nbVoyage = this.sc_nbVoyage * 1 + 1
      }
    }
  }
  verifEstFroid() {
    const maintenant = new Date().getTime()
    const sc_dateDerniereSituation = new Date(String(this.sc_dateDerniereSituation)).getTime()
    const res = (maintenant - sc_dateDerniereSituation) / 3600000
    if (res >= 24) {
      this.estFroid()
    }
    return res
  }
  estFroid() {
    this.sc_nbVoyage = 0
  }
}

export class Machine {
  mac_id: number
  ch_id: number
  site_id: number
  mac_code: string
  mac_categorie: string
  mac_desi: string
  mac_serie: string
  mac_obs: string
  mac_dateentree: string
  mac_datesortie: string
  mac_nbroues: string
  mac_caces: string
  mac_num_epack: string
  mac_meta: string

  constructor(uneMachine: Machine) {
    this.mac_id = uneMachine.mac_id
    this.ch_id = uneMachine.ch_id
    this.site_id = uneMachine.site_id
    this.mac_code = uneMachine.mac_code
    this.mac_categorie = uneMachine.mac_categorie
    this.mac_desi = uneMachine.mac_desi
    this.mac_serie = uneMachine.mac_serie
    this.mac_obs = uneMachine.mac_obs
    this.mac_dateentree = uneMachine.mac_dateentree
    this.mac_datesortie = uneMachine.mac_datesortie
    this.mac_nbroues = uneMachine.mac_nbroues
    this.mac_caces = uneMachine.mac_caces
    this.mac_num_epack = uneMachine.mac_num_epack
    this.mac_meta = uneMachine.mac_meta
  }
}

export class TransfertDA {
  private st_id: string
  private stda_id: string
  private stda_codeDepart: string
  private stda_codeArriver: string
  private stda_dateHeureDebut: Date | string
  private stda_dateHeureArriver: Date | string
  constructor(DA: TransfertDA)
  constructor(st_id: string, stda_id: string, stda_codeDepart: string, stda_codeArriver: string | null, stda_dateHeureDebut: string, stda_dateHeureArriver: string | null)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.st_id = args[0].st_id
      this.stda_id = args[0].stda_id
      this.stda_codeDepart = args[0].stda_codeDepart
      this.stda_codeArriver = args[0].stda_codeArriver
      this.stda_dateHeureArriver = args[0].stda_dateHeureArriver
      this.stda_dateHeureDebut = args[0].stda_dateHeureDebut
    } else {
      this.st_id = args[0]
      this.stda_id = args[1]
      this.stda_codeDepart = args[2]
      this.stda_codeArriver = args[3]
      this.stda_dateHeureDebut = args[4]
      this.stda_dateHeureArriver = args[5]
    }
  }
  getId() {
    return this.st_id
  }
  getDAId() {
    return this.stda_id
  }
  getCodeDepart() {
    return this.stda_codeDepart
  }
  getCodeArriver() {
    return this.stda_codeArriver
  }
  getDateHeureDebut() {
    return this.stda_dateHeureDebut
  }
  getDateHeureArriver() {
    return this.stda_dateHeureArriver
  }
  setArriverDA(stda_codeArriver: string, stda_dateHeureArriver: string) {
    this.stda_codeArriver = stda_codeArriver
    this.stda_dateHeureArriver = stda_dateHeureArriver
  }
  setCodeDepart(stda_codeDepart: string) {
    this.stda_codeDepart = stda_codeDepart
  }
  setCodeArriver(stda_codeArriver: string) {
    this.stda_codeArriver = stda_codeArriver
  }
}

export class Transfert {
  private st_id: string
  private sc_code: string
  private sl_code: string | null
  private st_numCharge: string | null
  private ut_id: number | null
  private mac_id: number | null
  private st_observation: string
  private AEnvoyerALaBD: boolean
  private DA: TransfertDA[]

  constructor(unTransfert: Transfert) {
    this.st_id = unTransfert.st_id
    this.sc_code = unTransfert.sc_code
    this.sl_code = unTransfert.sl_code
    this.st_numCharge = unTransfert.st_numCharge
    this.ut_id = unTransfert.ut_id
    this.mac_id = unTransfert.mac_id
    this.st_observation = unTransfert.st_observation
    this.AEnvoyerALaBD = unTransfert.AEnvoyerALaBD == undefined ? false : unTransfert.AEnvoyerALaBD
    this.DA = unTransfert.DA.map((d: TransfertDA) => {
      return new TransfertDA(d)
    })
  }

  getId() {
    return this.st_id
  }
  getCodeCuvier() {
    return this.sc_code
  }
  getCodeLaitier() {
    return this.sl_code
  }
  getNumCharge() {
    return this.st_numCharge
  }
  getUtilisateur() {
    return this.ut_id
  }
  getMachine() {
    return this.mac_id
  }
  getObservation() {
    return this.st_observation
  }
  getAEnvoyerALaBD() {
    return this.AEnvoyerALaBD
  }
  getDA() {
    return this.DA
  }
  getDerDA() {
    return this.DA[this.DA.length - 1]
  }
  setNumCharge(st_numCharge: string) {
    this.st_numCharge = st_numCharge
  }
  setCodeLaitier(sl_code: string) {
    this.sl_code = sl_code
  }
  setArriver(stda_codeArriver: string, stda_dateHeureArriver: string) {
    const trans: TransfertDA = new TransfertDA(this.getDerDA())
    trans.setArriverDA(stda_codeArriver, stda_dateHeureArriver)
    this.DA.pop()
    this.DA.push(trans)
  }
  setAEnvoyerALaBD(bool: boolean) {
    this.AEnvoyerALaBD = bool
  }
  setCodeCuvier(sc_code: string) {
    this.sc_code = sc_code
  }
}

export interface Message {
  sj_id: number
  ut_id: number
  sj_datetime: Date | string
  sj_contenus: string
  auteur: boolean | null
  modification: boolean | null
}

export class Maintenance {
  private sma_id: string
  private ut_id: string
  private sma_date: string
  private sma_tempsPasse: number
  private sma_horametre: string
  private sma_autreMaintenance: string
  private sma_pneu: string

  constructor()
  constructor(uneMaintenance: Maintenance)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sma_id = args[0].sma_id
      this.ut_id = args[0].ut_id
      this.sma_date = args[0].sma_date
      this.sma_tempsPasse = args[0].sma_tempsPasse
      this.sma_horametre = args[0].sma_horametre
      this.sma_autreMaintenance = args[0].sma_autreMaintenance
      this.sma_pneu = args[0].sma_pneu
    } else {
      this.sma_id = ""
      this.ut_id = ""
      this.sma_date = ""
      this.sma_tempsPasse = 0
      this.sma_horametre = ""
      this.sma_autreMaintenance = ""
      this.sma_pneu = ""
    }
  }

  getId() {
    return this.sma_id
  }
  getUtilisateur() {
    return this.ut_id
  }
  getDate() {
    return this.sma_date
  }
  getTempsPasse() {
    return this.sma_tempsPasse
  }
  getHorametre() {
    return this.sma_horametre
  }
  getAutreMaintenance() {
    return this.sma_autreMaintenance
  }
  getPneu() {
    return this.sma_pneu
  }

  setId(sma_id: string) {
    this.sma_id = sma_id
  }
  setUtilisateur(ut_id: string) {
    this.ut_id = ut_id
  }
  setDate(sma_date: string) {
    this.sma_date = sma_date
  }
  setTempsPasse(sma_tempsPasse: number) {
    this.sma_tempsPasse = sma_tempsPasse
  }
  setHorametre(sma_horametre: string) {
    this.sma_horametre = sma_horametre
  }
  setAutreMaintenance(sma_autreMaintenance: string) {
    this.sma_autreMaintenance = sma_autreMaintenance
  }
  setPneu(sma_pneu: string) {
    this.sma_pneu = sma_pneu
  }
}

export class DemandeMaintenance {
  private sdm_id: string
  private sdm_date: string
  private sdm_machine: number
  private sdm_panne: string
  private sdm_etatDemande: string
  private sdm_prioriter: string
  private maintenance: Maintenance
  private ut_id: string

  constructor()
  constructor(uneDemande: DemandeMaintenance)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sdm_id = args[0].sdm_id
      this.sdm_date = args[0].sdm_date
      this.sdm_machine = args[0].sdm_machine
      this.sdm_panne = args[0].sdm_panne
      this.sdm_etatDemande = args[0].sdm_etatDemande
      this.sdm_prioriter = args[0].sdm_prioriter
      if (args[0].maintenance != null && args[0].maintenance !== undefined) {
        this.maintenance = new Maintenance(args[0].maintenance)
      } else {
        this.maintenance = new Maintenance()
      }
      this.ut_id = args[0].ut_id
    } else {
      this.sdm_id = ""
      this.sdm_date = ""
      this.sdm_machine = -1
      this.sdm_panne = ""
      this.sdm_etatDemande = ""
      this.sdm_prioriter = ""
      this.maintenance = new Maintenance()
      this.ut_id = ""
    }
  }

  getId() {
    return this.sdm_id
  }
  getDate() {
    return this.sdm_date
  }
  getIDMachine() {
    return this.sdm_machine
  }
  getPanne() {
    return this.sdm_panne
  }
  getEtat() {
    return this.sdm_etatDemande
  }
  getPriorite() {
    return this.sdm_prioriter
  }
  getMaintenances() {
    return this.maintenance
  }
  getUtilisateur() {
    return this.ut_id
  }

  setId(sdm_id: string) {
    this.sdm_id = sdm_id
  }
  setDate(sdm_date: string) {
    this.sdm_date = sdm_date
  }
  setIDMachine(sdm_machine: number) {
    this.sdm_machine = sdm_machine
  }
  setPanne(sdm_panne: string) {
    this.sdm_panne = sdm_panne
  }
  setEtat(sdm_etatDemande: string) {
    this.sdm_etatDemande = sdm_etatDemande
  }
  setPriorite(sdm_prioriter: string) {
    this.sdm_prioriter = sdm_prioriter
  }
  setMaintenances(maintenance: Maintenance) {
    this.maintenance = maintenance
  }
  setUtilisateur(ut_id: string) {
    this.ut_id = ut_id
  }

  iniMaintenance() {
    this.setDate(sqlDate(new Date()))
    this.getMaintenances().setDate(sqlDate(new Date()))
    this.setPriorite("F")
    this.setEtat("D")
    this.getMaintenances().setTempsPasse(0)
  }
}

export interface Poste {
  sp_id: number
  ut_id: number
  sp_datetimeDebut: Date | string
  sp_datetimeFin: Date | string
  sp_observation: string | null
  sp_poste: string
  sp_DebutFin: string
}

export class Zone {
  private sz_code: string
  private sz_nomZone: string
  private desactive: boolean
  private nomCuvier: string
  private sl_code: string
  private num_charge: string
  private sz_situation: string
  private sg_code: string

  constructor(zone: Zone)
  constructor()
  constructor(sz_code: string, sz_nomZone: string, sz_situation: string, sg_code: string)
  constructor(...args: any[]) {
    if (args.length == 4) {
      this.sz_code = args[0]
      this.sz_nomZone = args[1]
      this.sz_situation = args[2]
      this.sg_code = args[3]
    } else if (args.length == 1) {
      this.sz_code = args[0].sz_code
      this.sz_nomZone = args[0].sz_nomZone
      this.sz_situation = args[0].sz_situation
      this.sg_code = args[0].sg_code
    } else {
      this.sz_code = ""
      this.sz_nomZone = ""
      this.sz_situation = ""
      this.sg_code = ""
    }
    this.desactive = false
    this.nomCuvier = ""
    this.num_charge = ""
    this.sl_code = ""
  }

  getNomCuvier() {
    return this.nomCuvier
  }
  getNumCharge() {
    return this.num_charge
  }
  getSl_Code() {
    return this.sl_code
  }
  getCode() {
    return this.sz_code
  }
  getActive() {
    return this.desactive
  }
  getNomZone() {
    return this.sz_nomZone
  }
  getSituation() {
    return this.sz_situation
  }
  getGroupeZone() {
    return this.sg_code
  }
  setNomCuvier(nomCuvier: string) {
    this.nomCuvier = nomCuvier
  }
  setSl_Code(sl_code: string) {
    this.sl_code = sl_code
  }
  setNumCharge(num_charge: string) {
    this.num_charge = num_charge
  }
  setActive(desactive: boolean) {
    this.desactive = desactive
  }
}
class ZonesCuviers {
  protected lesCuviers: Cuvier[]
  protected lesCodesZones: Zone[]
  protected zone = ""
  constructor(typezone: string) {
    this.zone = typezone
    this.lesCuviers = []
    this.lesCodesZones = []
  }
  initCuviers(desCuviers: Cuvier[]) {
    this.lesCuviers = desCuviers.filter((c: Cuvier) => c.getGroupe() == this.zone)
  }
  initZone(lesZone: Zone[]) {
    this.lesCodesZones = lesZone.filter((z: Zone) => z.getGroupeZone() == this.zone)
  }
  getCuviers() {
    return this.lesCuviers
  }
  getCodesZones() {
    return this.lesCodesZones
  }
  getZone() {
    return this.zone
  }
  setNomCuvier(nomCuvier: string, sz_code: string): boolean {
    const index = this.lesCodesZones.findIndex((uneZone: Zone) => sz_code == uneZone.getCode())
    if (index == -1) {
      return false
    } else {
      this.lesCodesZones[index].setNomCuvier(nomCuvier)
      return true
    }
  }
  setNumCharge(num_charge: string, sz_code: string): boolean {
    const index = this.lesCodesZones.findIndex((uneZone: Zone) => sz_code == uneZone.getCode())
    if (index == -1) {
      return false
    } else {
      this.lesCodesZones[index].setNumCharge(num_charge)
      return true
    }
  }
  setSl_Code(sl_code: string, sz_code: string): boolean {
    const index = this.lesCodesZones.findIndex((uneZone: Zone) => sz_code == uneZone.getCode())
    if (index == -1) {
      return false
    } else {
      this.lesCodesZones[index].setSl_Code(sl_code)
      return true
    }
  }
  accepteCuvier(unCuvier: Cuvier): boolean {
    if (unCuvier.getGroupe() == this.zone) {
      return true
    } else {
      return false
    }
  }
  trouveZone(sz_code: string): Zone {
    const laZone = this.lesCodesZones.find((z: Zone) => z.getCode() == sz_code)
    if (laZone !== undefined) {
      return laZone
    } else {
      return new Zone("", "", "", "")
    }
  }
  trouveZoneFosse(): Zone[] {
    const laZone = this.lesCodesZones.filter((z: Zone) => z.getCode().substring(0, 1) == "F")
    if (laZone !== undefined) {
      return laZone
    } else {
      return []
    }
  }
  desactiveZone(desactive: boolean) {
    const lesZone = this.lesCodesZones.filter((z: Zone) => z.getGroupeZone() == this.zone)!
    lesZone.map((z: Zone) => {
      z.setActive(desactive)
    })
  }
}

export class ZonesPS extends ZonesCuviers {
  constructor() {
    super("PS")
  }
  getFour(): Zone[] {
    return [this.trouveZone("PS")]
  }
  getStokage(): Zone {
    return this.trouveZone("SPS")
  }
  getBatiment(): Zone {
    return this.trouveZone("Batiment")
  }
  getArrosage(): Zone {
    return this.trouveZone("APS")
  }
}

export class ZonesCAD extends ZonesCuviers {
  constructor() {
    super("CAD")
  }
  getFour(): Zone[] {
    return [this.trouveZone("CAD1"), this.trouveZone("CAD2"), this.trouveZone("CAD3")]
  }
  getStokage(): Zone {
    return this.trouveZone("SCAD")
  }
  getBatiment(): Zone {
    return this.trouveZone("Batiment")
  }
  getArrosage(): Zone {
    return this.trouveZone("ACAD")
  }
}

export class ZonesC extends ZonesCuviers {
  constructor() {
    super("C")
  }
  getFour(): Zone[] {
    return [this.trouveZone("C1"), this.trouveZone("C2"), this.trouveZone("C3")]
  }
  getStokage(): Zone {
    return this.trouveZone("SC")
  }
  getFosse() {
    return this.trouveZoneFosse()
  }
  getFosse14(): Zone[] {
    return [this.trouveZone("F1"), this.trouveZone("F2"), this.trouveZone("F3"), this.trouveZone("F4")]
  }
  getFosse58(): Zone[] {
    return [this.trouveZone("F5"), this.trouveZone("F6"), this.trouveZone("F7"), this.trouveZone("F8")]
  }
}

export class ChecklisteControle {
  private code: string
  private libelle: string
  private libelle_AL: string
  private libelle_FR: string
  private observation: string
  private reponse: boolean | null

  constructor()
  constructor(unControle: ChecklisteControle)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.code = args[0].code
      this.libelle = args[0].libelle
      this.libelle_AL = args[0].libelle_AL
      this.libelle_FR = args[0].libelle_FR
      this.observation = args[0].observation
      this.reponse = args[0].reponse
    } else {
      this.code = ""
      this.libelle = ""
      this.libelle_AL = ""
      this.libelle_FR = ""
      this.observation = ""
      this.reponse = null
    }
  }

  getLibelleLangue(lang: string) {
    if (lang == "FR") {
      return this.libelle_FR
    }
    return this.libelle_AL
  }
  getReponse() {
    return this.reponse
  }
  setLibelle(leLibelle: string) {
    this.libelle = leLibelle
  }
}

export class Checkliste {
  private controles: ChecklisteControle[]
  private texte: string
  private texte_AL: string
  private texte_FR: string

  constructor()
  constructor(uneCheckListe: Checkliste)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.controles = args[0].controles.map((c: ChecklisteControle) => {
        return new ChecklisteControle(c)
      })
      this.texte = args[0].texte
      this.texte_AL = args[0].texte_AL
      this.texte_FR = args[0].texte_FR
    } else {
      this.controles = []
      this.texte = ""
      this.texte_AL = ""
      this.texte_FR = ""
    }
  }

  getControles() {
    return this.controles
  }
  getTexteLangue(lang: string) {
    if (lang == "FR") {
      return this.texte_FR
    }
    return this.texte_AL
  }
  setTexte(leTexte: string) {
    this.texte = leTexte
  }
}

export class CheckListeCategorie {
  private engin: string
  private tm_id: number
  private checklistes: Checkliste[]

  constructor()
  constructor(uneCategorie: CheckListeCategorie)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.engin = args[0].engin
      this.tm_id = args[0].tm_id
      this.checklistes = args[0].checklistes.map((c: Checkliste) => {
        return new Checkliste(c)
      })
    } else {
      this.engin = ""
      this.tm_id = -1
      this.checklistes = []
    }
  }

  getId() {
    return this.tm_id
  }
  getEngin() {
    return this.engin
  }
  getCheckListes() {
    return this.checklistes
  }
}
