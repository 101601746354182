import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","dark":"","dense":"","flat":"","color":"teal lighten-2"}},[_c(VToolbarTitle,{staticClass:"headline text-uppercase"},[_c('span',[_vm._v("SAB-SCHLACKE")])]),_c(VSpacer),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.utilisateur))]),_c('div',{staticStyle:{"width":"130px"}},[_c(VSelect,{staticClass:"ml-2",attrs:{"items":_vm.optionLang,"item-text":"label","item-value":"value","width":"100","hide-details":""},on:{"change":function($event){return _vm.modifierLang()}},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}})],1),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"to":"/about"}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.dictionnaire[0]))])],1)],1)],1)],1),_c(VMain,[_c('router-view')],1),_c('confirm',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }