import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import { cleControle, dbSAB, sqlDate, variables } from "../plugins/variables"
import { Cuvier, Machine, Transfert, Zone } from "./types"

Vue.use(Vuex)
const debug = process.env.NODE_ENV === "development"
const jour = sqlDate(new Date())

export default new Vuex.Store({
  state: {
    version: "01",
    ajaxurl: debug ? "http://localhost/SAB/SAB-chantier/php/" : "/php/",
    debug: debug,
    jour: jour,
    co: false,
    h24: false,
    compteurEnMarche: true,
    ut_id: 0,
    utilisateur: "",
    lang: "AL",
    salaries: [],
    zones: [] as Zone[],
    cuviers: [] as Cuvier[],
    machines: [] as Machine[],
    transferts: [] as Transfert[],
    laitier: [] as string[],
    informations: [] as any[],
    lePosteActuel: {} as any
  },
  mutations: {
    majJour(state, ndate) {
      state.jour = ndate
    },
    majH24(state, h24) {
      state.h24 = h24
      if (h24) {
        state.jour = sqlDate(new Date())
      }
    },
    majCo(state) {
      state.co = true
    },
    majDeco(state) {
      state.co = false
    },
    actualiserJour(state) {
      state.jour = sqlDate(new Date())
    },
    majSalaries(state, salaries) {
      state.salaries = salaries.slice()
    },
    majCuvier(state, cuviers) {
      state.cuviers = cuviers.map((c: Cuvier) => {
        const cu = new Cuvier(c)
        return cu
      })
    },
    majTransferts(state, transferts) {
      state.transferts = transferts.map((t: Transfert) => {
        return new Transfert(t)
      })
    },
    majMachine(state, machines) {
      state.machines = machines.map((m: Machine) => {
        return new Machine(m)
      })
    },
    majLaitier(state, laitier) {
      state.laitier = laitier.slice()
    },
    majZone(state, zones) {
      state.zones = zones.map((z: Zone) => {
        return new Zone(z)
      })
    },
    majUtilisateur(state, nom) {
      state.utilisateur = nom
    },
    majLangue(state, lang) {
      state.lang = lang
      variables.langue = lang
    },
    majInformation(state, informations) {
      state.informations = informations
    },
    majPosteActuel(state, lePosteActuel) {
      state.lePosteActuel = lePosteActuel
    },
    majCompteur(state, marche) {
      state.compteurEnMarche = marche
    }
  },
  actions: {
    chargerTables(context) {
      const url = context.state.ajaxurl + "ajax_appli.php"
      axios
        .get(url, {
          params: {
            cle: cleControle(),
            action: "TABLES"
          }
        })
        .then(response => {
          if (response.data.salaries) {
            context.commit("majSalaries", response.data.salaries)
          }
        })
        .catch(() => {})
      axios
        .get(context.state.ajaxurl + "laitiers.php", {
          params: {
            action: "INITIALISER"
          }
        })
        .then(async response => {
          const db = await dbSAB()
          if (response.data.laitier) {
            const lesLaitier = response.data.laitier
            context.commit("majLaitier", lesLaitier)
            const store = db.transaction("sab_laitiers", "readwrite").store
            for (const unLaitier of lesLaitier) {
              store.put({
                sl_code: unLaitier.sl_code,
                aEnvoyerALaBD: false
              })
            }
          }
          if (response.data.zone) {
            const lesZones = response.data.zone
            context.commit("majZone", lesZones)
            const store = db.transaction("sab_zones", "readwrite").store
            for (const uneZone of lesZones) {
              store.put({
                sz_code: uneZone.sz_code,
                sz_nomZone: uneZone.sz_nomZone,
                desactive: uneZone.desactive === undefined ? "" : uneZone.desactive,
                nomCuvier: uneZone.nomCuvier === undefined ? "" : uneZone.nomCuvier,
                sz_situation: uneZone.sz_situation,
                sg_code: uneZone.sg_code,
                aEnvoyerALaBD: false
              })
            }
          }
          if (response.data.machine) {
            const lesMachines = response.data.machine
            context.commit("majMachine", lesMachines)
            const store = db.transaction("sab_machines", "readwrite").store
            for (const uneMachine of lesMachines) {
              store.put({
                mac_id: uneMachine.mac_id,
                ch_id: uneMachine.ch_id,
                site_id: uneMachine.site_id,
                mac_code: uneMachine.mac_code,
                mac_categorie: uneMachine.mac_categorie,
                mac_desi: uneMachine.mac_desi,
                mac_serie: uneMachine.mac_serie,
                mac_obs: uneMachine.mac_obs,
                mac_dateentree: uneMachine.mac_dateentree,
                mac_datesortie: uneMachine.mac_datesortie,
                mac_nbroues: uneMachine.mac_nbroues,
                mac_caces: uneMachine.mac_caces,
                mac_num_epack: uneMachine.mac_num_epack,
                mac_meta: uneMachine.mac_meta,
                aEnvoyerALaBD: false
              })
            }
          }
          if (response.data.transfert) {
            const lesTransferts = response.data.transfert
            context.commit("majTransferts", lesTransferts)
            const store = db.transaction("sab_transfert", "readwrite").store
            for (const unTransfert of lesTransferts) {
              store.put({
                st_id: unTransfert.st_id,
                sc_code: unTransfert.sc_code,
                sl_code: unTransfert.sl_code,
                st_numCharge: unTransfert.st_numCharge,
                ut_id: unTransfert.ut_id,
                mac_id: unTransfert.mac_id,
                st_observation: unTransfert.st_observation,
                DA: unTransfert.DA,
                AEnvoyerALaBD: false
              })
            }
          }
          if (response.data.cuvier) {
            const lesCuviers = response.data.cuvier
            context.commit("majCuvier", lesCuviers)
            const store = db.transaction("sab_cuvier", "readwrite").store
            for (const unCuvier of lesCuviers) {
              store.put({
                sc_code: unCuvier.sc_code,
                sc_situation: unCuvier.sc_situation,
                sc_dateDerniereSituation: unCuvier.sc_dateDerniereSituation,
                sc_dateHeureDebutArrossage: unCuvier.sc_dateHeureDebutArrossage,
                sc_nbVoyage: unCuvier.sc_nbVoyage,
                sc_dateEntrer: unCuvier.sc_dateEntrer,
                sc_dateSortie: unCuvier.sc_dateSortie,
                sc_observation: unCuvier.sc_observation,
                sg_code: unCuvier.sg_code,
                AEnvoyerALaBD: false
              })
            }
          }
          db.close()
        })
        .catch(async () => {
          const db = await dbSAB()

          context.commit("majLaitier", await db.getAll("sab_laitiers"))
          context.commit("majZone", await db.getAll("sab_zones"))
          context.commit("majMachine", await db.getAll("sab_machines"))
          context.commit("majTransferts", await db.getAll("sab_transfert"))
          context.commit("majCuvier", await db.getAll("sab_cuvier"))
          context.commit("majInformation", await db.getAll("sab_information"))

          db.close()
        })
    },
    posteActuel(context, ut_id: number) {
      axios
        .post(context.state.ajaxurl + "laitiers.php", {
          action: "POSTE_ACTUEL",
          ut_id: ut_id
        })
        .then(async response => {
          const lePosteActuel = response.data.poste_actuel
          context.commit("majPosteActuel", lePosteActuel)
          const db = await dbSAB()
          const store = db.transaction("sab_posteActuel", "readwrite").store
          store.clear()
          store.put({
            sp_id: lePosteActuel.sp_id,
            ut_id: lePosteActuel.ut_id,
            sp_datetimeDebut: lePosteActuel.sp_datetimeDebut,
            sp_datetimeFin: lePosteActuel.sp_datetimeFin,
            sp_poste: lePosteActuel.sp_poste,
            sp_observation: lePosteActuel.sp_observation
          })
        })
        .catch(async () => {
          const db = await dbSAB()

          context.commit("majPosteActuel", await db.getAll("sab_posteActuel"))

          db.close()
        })
    }
  },
  modules: {}
})
