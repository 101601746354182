
import Vue from "vue"
import { sqlDate, dateFromString, verifConnexion, variables, dateFrancaise, dbSAB } from "../plugins/variables"

export default Vue.extend({
  name: "Layout",
  data() {
    return {
      iTab: "",
      menuDate: false,
      calendrier: "de-DE",
      h24: false,
      date: sqlDate(new Date()),
      compteur: 60,
      compteurEnMarche: true,
      dictionnaire: [] as string[],
      unsubscribe: null as any
    }
  },
  methods: {
    majDate() {
      this.$store.commit("majJour", this.date)
      ;(this.$refs.menu as any).save(this.date)
    },
    modifjour(nbj: number) {
      const dte = dateFromString(this.date)
      this.date = sqlDate(new Date(dte.getFullYear(), dte.getMonth(), dte.getDate() + nbj, 12))
      this.majDate()
    },
    compteARebourg() {
      if (this.compteurEnMarche == true) {
        if (this.compteur > 0) {
          this.compteur -= 1
        } else {
          this.compteur = 60
          this.$store.dispatch("chargerTables")
          this.$store.commit("majJour", this.date)
          this.decoAuto()
        }
      }
    },
    decoAuto() {
      const aujourdhui = new Date()
      const dateCo = new Date(variables.date)
      if (aujourdhui.getDate() == dateCo.getDate()) {
        if ((aujourdhui.getHours() > 13.5 && dateCo.getHours() >= 5 && dateCo.getHours() <= 13) || (aujourdhui.getHours() > 21.5 && dateCo.getHours() >= 13 && dateCo.getHours() <= 21)) {
          this.$store.commit("majDeco")
          return false
        }
        return true
      } else {
        this.$store.commit("majDeco")
        return false
      }
    },
    choixlangue(lang: string) {
      switch (lang) {
        case "FR":
          this.dictionnaire = ["Journée du", "Transferts laitiers", "Journal", "Poste", "Maintenance", "Annuler"]
          this.calendrier = "fr-FR"
          break
        case "AL":
          this.dictionnaire = ["Tag", "Schlacken transport", "Zeitung", "Ende der Schicht", "Wartung", "Abbrechen"]
          this.calendrier = "de-DE"
          break
      }
    }
  },
  created() {
    verifConnexion(this)
    if (this.decoAuto() == true) {
      this.iTab = ""
      this.$store.dispatch("chargerTables")
      this.unsubscribe = this.$store.subscribe(mutation => {
        switch (mutation.type) {
          case "majLangue":
            this.choixlangue(this.$store.state.lang)
            break
          case "majCompteur":
            this.compteurEnMarche = this.$store.state.compteurEnMarche
            break
        }
      })
    }
  },
  mounted() {
    this.$router.push("/poste")
    this.choixlangue(variables.langue)
    setInterval(this.compteARebourg, 1000)
  },
  computed: {
    computedDateFormatted(): string {
      return dateFrancaise(this.date)
    }
  },
  beforeDestroy() {
    this.unsubscribe = []
    clearInterval()
  }
})
