import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "@/views/Layout.vue"
import { variables } from "../plugins/variables"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Layout,
    children: [
      {
        path: "/poste",
        component: () => import("@/views/Poste.vue")
      },
      {
        path: "/journal",
        component: () => import("@/views/Journal.vue")
      },
      {
        path: "/interfaceChantier",
        component: () => import("@/views/InterfaceChantier.vue")
      },
      {
        path: "/maintenance",
        component: () => import("@/views/Maintenance.vue")
      },
      {
        path: "/about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/login")) {
    next()
  } else {
    if (!variables.authentifie) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  }
})

export default router
