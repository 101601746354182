
import Vue from "vue"
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.openDialogConfirm
 * }
 *
 * TypeScript
 * interface IConfirmer extends Vue {
 *   openDialogConfirm(title: string, message: string, options: object): Promise<boolean>
 * }
 * declare module 'vue/types/vue' {
 * // 3. Déclarez l'augmentation pour Vue
 * interface Vue {
 *   $Confirmer: (title: string, message: string, options: object) => Promise<boolean>
 * }
 *
 * mounted() {
 *    this.$root.$Confirmer = (title: string, message: string, options: object) =>
 *      (this.$refs.confirm as IConfirmer).openDialogConfirm(title, message, options)
 * }
 *
 * type PromiseResolve<T> = (value?: T | PromiseLike<T>) => boolean
 * type PromiseReject = (error?: any) => void
 */

export default Vue.extend({
  name: "Confirm",
  data: () => ({
    dialog: false,
    resolve: null as any,
    reject: null as any,
    message: null as null | string,
    title: null as null | string,
    options: {
      color: "primary",
      width: 290,
      zIndex: 200
    }
  }),
  methods: {
    openDialogConfirm(title: string, message: string, options: object): Promise<boolean> {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise<boolean>((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree(): void {
      this.resolve(true)
      this.dialog = false
    },
    cancel(): void {
      this.resolve(false)
      this.dialog = false
    }
  }
})
