
import Vue from "vue"
import confirm from "@/components/Confirm.vue"
import { dbSAB, variables } from "./plugins/variables"

interface IConfirmer extends Vue {
  openDialogConfirm(title: string, message: string, options: object): Promise<boolean>
}
declare module "vue/types/vue" {
  // 3. Déclarez l'augmentation pour Vue
  interface Vue {
    $Confirmer: (title: string, message: string, options: object) => Promise<boolean>
  }
}

export default Vue.extend({
  name: "App",
  components: { confirm },
  data() {
    return {
      utilisateur: this.$store.state.utilisateur,
      unsubscribe: null as any,
      lang: "AL",
      optionLang: [
        { value: "AL", label: "Deutsch" },
        { value: "FR", label: "Français" }
      ],
      dictionnaire: [] as string[]
    }
  },
  methods: {
    async dejaConnecter() {
      const db = await dbSAB()
      const laCo = await db.getAll("sab_co")
      if (laCo.length > 0) {
        if (laCo[0].authentifie) {
          variables.authentifie = laCo[0].authentifie
          variables.langue = laCo[0].langue
          variables.date = laCo[0].date
          this.lang = laCo[0].langue
          this.modifierLang()
          this.utilisateur = laCo[0].nom
          this.$store.commit("majCo")
          this.$store.commit("majUtilisateur", laCo[0].nom)
          const lePoste = await db.getAll("sab_posteActuel")
          if (lePoste.length > 0) {
            this.$store.commit("majPosteActuel", lePoste[0])
          }
          this.$router.push("/")
        }
      } else {
        const db = await dbSAB()
        const store = db.transaction("sab_machines", "readwrite").store
        await store.clear()
        db.close()
      }
    },
    modifierLang() {
      this.$store.commit("majLangue", this.lang)
      this.choixlangue(this.lang)
    },
    choixlangue(lang: string) {
      switch (lang) {
        case "FR":
          this.dictionnaire = ["A propos..."]
          break
        case "AL":
          this.dictionnaire = ["Um..."]
          break
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majUtilisateur":
          this.utilisateur = this.$store.state.utilisateur
          break
        case "majLangue":
          this.lang = this.$store.state.lang
          this.choixlangue(this.lang)
      }
    })
  },
  mounted() {
    this.dejaConnecter()
    this.$store.commit("majLangue", this.lang)
    this.choixlangue(this.lang)
  },
  beforeDestroy() {
    this.unsubscribe = []
  }
})
