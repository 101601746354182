import store from "../store"

export function sqlDate(dte: Date): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? "0" : "") + mo
  const dd = (d < 10 ? "0" : "") + d
  return `${y}-${mmo}-${dd}`
}
export function dateFrancaise(dte: string | Date): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  dte = new Date(dte)
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? "0" : "") + mo
  const dd = (d < 10 ? "0" : "") + d
  if (store.state.lang == "FR") {
    return `${dd}/${mmo}/${y}`
  } else {
    return `${dd}.${mmo}.${y}`
  }
}
export function sqlDatetime(dte: Date): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? "0" : "") + mo
  const dd = (d < 10 ? "0" : "") + d

  const h = dte.getHours()
  const mn = dte.getMinutes()
  const hh = (h < 10 ? "0" : "") + h
  const mmn = (mn < 10 ? "0" : "") + mn

  return `${y}-${mmo}-${dd} ${hh}:${mmn}`
}
export function dateTimeFrancaise(dte: Date | string): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  if (typeof dte === "string") {
    dte = new Date(dte)
  }
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? "0" : "") + mo
  const dd = (d < 10 ? "0" : "") + d

  const h = dte.getHours()
  const mn = dte.getMinutes()
  const hh = (h < 10 ? "0" : "") + h
  const mmn = (mn < 10 ? "0" : "") + mn

  if (store.state.lang == "FR") {
    return `${dd}/${mmo}/${y} ${hh}:${mmn}`
  } else {
    return `${dd}.${mmo}.${y} ${hh}:${mmn}`
  }
}
export function datetimeFromString(stdatetime: string): Date | null {
  if (stdatetime == null) return new Date()
  const regex1 = /(\d*)-(\d*)-(\d*)\s+(\d*):(\d*)/g
  const dte = regex1.exec(stdatetime)
  if (dte) {
    const date = new Date(parseInt(dte[1]), parseInt(dte[2]) - 1, parseInt(dte[3]), parseInt(dte[4]), parseInt(dte[5]))
    return date
  }
  return null
}
export function dateFromString(stdate: string): Date {
  if (stdate == null) return new Date()
  const [year, month, day] = stdate.split("-")
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12)
  return date
}
export function dateDiff(date1: Date, date2: Date) {
  const diff = { sec: 0, min: 0 }
  let tmp = date2.getTime() - date1.getTime()

  tmp = Math.floor(tmp / 1000)
  diff.sec = tmp % 60

  tmp = Math.floor((tmp - diff.sec) / 60)
  diff.min = tmp % 60

  return diff.min
}
export function cleControle(): string {
  // la date est codée en base 31
  const dte = new Date()
  dte.setHours(12)
  const n = ((dte.getMonth() + 1) * 100 + dte.getDate()) * 10000 + dte.getFullYear()
  const cle = n.toString(31)
  return cle
}
export function verifConnexion(vm: Vue) {
  let ut_id = vm.$store.state.ut_id
  if (ut_id == null || ut_id == 0) {
    ut_id = localStorage.getItem("chantiersab_identifiant")
    if (ut_id == null) ut_id = 0
    if (ut_id > 0) {
      //re-connexion
      vm.$store.commit("login", { ut_id: +ut_id, nom: localStorage.getItem("chantiersab_utilisateur") })
    } else {
      vm.$router.replace("/login")
    }
  }
}

export const variables = {
  authentifie: false,
  date: "2020-01-01 00:00",
  langue: "AL"
}

import { openDB, IDBPDatabase } from "idb"

export function dbSAB(): Promise<IDBPDatabase<unknown>> {
  const db = openDB("sab-ic", 3, {
    async upgrade(db) {
      if (!db.objectStoreNames.contains("sab_cuvier")) {
        db.createObjectStore("sab_cuvier", { keyPath: "sc_code" }).createIndex("sc_code", "sc_code")
      }
      if (!db.objectStoreNames.contains("sab_transfert")) {
        db.createObjectStore("sab_transfert", { keyPath: "st_id" }).createIndex("st_id", "st_id")
      }
      if (!db.objectStoreNames.contains("machine_utiliser")) {
        db.createObjectStore("machine_utiliser", { keyPath: "ut_id" }).createIndex("ut_id", "ut_id")
      }
      if (!db.objectStoreNames.contains("sab_co")) {
        db.createObjectStore("sab_co", { keyPath: "ut_id" }).createIndex("ut_id", "ut_id")
      }
      if (!db.objectStoreNames.contains("sab_information")) {
        db.createObjectStore("sab_information", { keyPath: "nom" }).createIndex("nom", "nom")
      }
      if (!db.objectStoreNames.contains("sab_machines")) {
        db.createObjectStore("sab_machines", { keyPath: "mac_id" }).createIndex("mac_id", "mac_id")
      }
      if (!db.objectStoreNames.contains("sab_laitiers")) {
        db.createObjectStore("sab_laitiers", { keyPath: "sl_code" }).createIndex("sl_code", "sl_code")
      }
      if (!db.objectStoreNames.contains("sab_zones")) {
        db.createObjectStore("sab_zones", { keyPath: "sz_code" }).createIndex("sz_code", "sz_code")
      }
      if (!db.objectStoreNames.contains("sab_posteActuel")) {
        db.createObjectStore("sab_posteActuel", { keyPath: "sp_id" }).createIndex("sp_id", "sp_id")
      }
      if (!db.objectStoreNames.contains("sab_checkliste")) {
        db.createObjectStore("sab_checkliste", { keyPath: "cl_id" }).createIndex("cl_id", "cl_id")
      }
    }
  })
  return db
}
